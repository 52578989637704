.navbar {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    padding: 12px 0px;
    background-color: #fff;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.075);
    z-index: 99;
}

.navbar .logo {
    width: 80px;
    /* height: 50px; */
}

.navbar .nav-items {
    display: flex;
    align-items: center;
}

.navbar .nav-items a {
    text-decoration: none !important;
}

.navbar .nav-items .nav-link {
    position: relative;
    font-size: 18px;
    color: var(--secondary);
    margin-right: 20px;
    text-decoration: none !important;
    transition: 0.3s;
}

.navbar .nav-items .nav-link:hover,
.navbar .nav-items .nav-link.active {
    color: var(--primary);
}

.navbar .nav-items .nav-link i {
    padding-right: 5px;
    transition: 0.2s;
}

.navbar .nav-items .nav-link:hover i {
    color: var(--primary);
    transform: translateY(-5px);
}

.navbar .nav-items .nav-link b,
.navbar .nav-items .nav-btn b {
    font-weight: 500;
}

.navbar .nav-items .nav-btn {
    position: relative;
    font-size: 18px;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 6px;
    background-color: var(--primary);
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.20);
    transition: 0.3s;
}

.navbar .nav-items .nav-btn i {
    padding-right: 5px;
}

.navbar .nav-items .nav-btn:hover {
    transform: translateY(-5px);
    box-shadow: 1px 5px 10px rgba(0, 0, 0, 0.20);
}

.bg-style {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: url('../img/bg-img.jpg') no-repeat;
    background-position: center;
    background-size: cover;
    min-height: 100vh;
    padding-top: 80px;
}

.bg-style::after {
    content: '';
    position: absolute;
    left: 0px;
    bottom: 0px;
    /* background-color: var(--primary); */
    background: url('../img/bg-img.jpg') no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 80%;
    clip-path: polygon(100% 28%, 0% 100%, 100% 100%);
    z-index: -1;
}

.upload-card,
.track-card {
    /* position: absolute; */
    background-color: rgba(255, 255, 255, 0.333);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    padding: 30px;
    /* min-width: 50%; */
    border-radius: 40px;
    box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.075);
    transition: 0.3s;
}

.upload-card.reupload-card {
    box-shadow: none;
    padding: 0px;
}

.upload-card .webcam-card,
.upload-card .photo-upload {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 278px;
    height: 337px;
    padding: 7px 8px 0px 8px;
    text-align: center;
    background-color: #fff;
    border: 3px dashed #61616757;
    border-radius: 20px;
    transition: 0.3s;
    cursor: pointer;
}


.upload-card .webcam-card:hover,
.upload-card .photo-upload:hover {
    border-color: var(--primary);
}

.disable-upload {
    opacity: 0.8;
    pointer-events: none;
}

.upload-card .webcam-horizontal {
    width: 350px;
    height: 238px;
}

.upload-card .webcam-horizontal .webcam-stream {
    position: fixed !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 812px;
    height: 400px;
    border-radius: 20px;
    overflow: hidden;
}

.upload-card .webcam-card i,
.upload-card .photo-upload i {
    font-size: 2.5rem;
    color: var(--primary);
}

.upload-card .webcam-card h6,
.upload-card .photo-upload h6 {
    font-family: 'Outfit-SemiBold';
    font-size: 20px;
    margin-top: 15px;
    margin-bottom: 13px;
}

.upload-card .webcam-card p,
.upload-card .photo-upload p {
    font-family: 'Outfit-Regular';
    font-size: 14px;
    margin-bottom: 0px;
    color: var(--secondary);
}

.upload-card .webcam-card {
    position: relative;
    overflow: hidden;
}

.upload-card .webcam-card .webcam-stream {
    position: absolute;
    z-index: 9;
}

.upload-card .webcam-card video {
    width: 264px;
    height: 325px;
    border-radius: 20px;
}

.upload-card .webcam-horizontal video {
    position: relative;
    width: 813px;
    height: 400px;
}

.upload-card .webcam-card .take-photo-btn {
    position: absolute;
    bottom: 15px;
    left: 50%;
    transform: translateX(-50%);
    display: block;
    width: 100%;
    padding: 10px;
    background: transparent;
    border: none;
    color: #fff9;
    font-family: 'Outfit-SemiBold';
    font-size: 18px;
    line-height: 20px;
    z-index: 10;
}

.upload-card .webcam-card .close-btn {
    position: absolute;
    top: 15px;
    right: 20px;
    color: #fff;
    font-size: 22px;
    background: transparent;
    border: none;
    z-index: 10;
}

/* .upload-card .webcam-horizontal .close-btn{
    top: 60px;
}

.upload-card .webcam-card .take-photo-btn{
    bottom: 50px;
} */

.upload-card .webcam-card .close-btn i {
    color: #fff !important;
}

.upload-card .upload-list h4 {
    font-family: 'Outfit-Bold';
    font-size: 20px;
    color: #fff;
    margin-bottom: 10px;
}

.upload-card .upload-list ol {
    font-family: 'Outfit-SemiBold';
    padding-left: 1rem;
    margin-bottom: 0px;
}

.upload-card .upload-list ol li {
    margin-bottom: 15px;
    color: #fff;
}

.upload-card .upload-list ol li label {
    font-family: 'Outfit-SemiBold';
    font-size: 16px;
    color: #fff;
    margin-top: 15px;
    margin-bottom: 5px;
}

.upload-card .upload-list ol li label:first-child {
    margin-top: 0px;
}

.upload-card .upload-list ol li .upload-file-card {
    min-width: 340px;
    padding: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.072);
    border: 2px solid transparent;
    border-radius: 10px;
    transition: 0.2s;
    cursor: pointer;
}

.upload-card .upload-list ol li .upload-file-card.active {
    border-color: var(--primary);
}

.upload-card .upload-list ol li .upload-file-card:hover {
    transform: translateY(-3px);
}


.upload-card .upload-list ol li .upload-file-card p {
    font-family: 'Outfit-SemiBold';
    font-size: 14px;
    color: #000;
    padding: 0px 10px;
    margin-bottom: 0px;
}

.upload-card .upload-list ol li .upload-file-card i {
    font-size: 26px;
}

.upload-card .upload-list ol li .upload-file-card i.color-primary {
    color: var(--primary);
}

.upload-card .upload-list ol li .upload-file-card i.color-green {
    color: #05b105
}

.upload-card .upload-list ol li .upload-file-card i.color-red {
    color: #df1010
}

.upload-card .upload-list ol li .upload-file-card i.rotate-ani {
    color: var(--primary);
    animation: rotateAni 1s infinite ease-in-out;
}

@keyframes rotateAni {
    0% {
        rotate: 0deg;
    }

    100% {
        rotate: 360deg;
    }
}

.upload-card .submit-btn {
    display: flex;
    align-items: center;
    font-family: 'Outfit-SemiBold';
    font-size: 18px;
    margin: 20px auto 0px;
    padding: 15px 30px;
    border: none;
    border-radius: 50px;
    color: var(--primary);
    background-color: #fff;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.15);
    transition: 0.3s;
}

.upload-card .submit-btn .loading-state {
    display: none;
}

.upload-card .submit-btn.is-loading {
    pointer-events: none;
    cursor: none;
    opacity: 0.9;
}

.upload-card .submit-btn.is-loading .submit-state {
    display: none;
}

.upload-card .submit-btn.is-loading .loading-state {
    display: block;
}

.upload-card .submit-btn:hover {
    color: #fff;
    background-color: var(--primary);
}

.upload-card .submit-btn i {
    font-size: 24px;
    padding-right: 10px;
    transition: 0.1s;
}

.upload-card .submit-btn:hover i {
    transform: translateY(-3px);
}

.track-card .track-input {
    width: 50%;
    margin-bottom: 20px;
}

.track-card .track-input label {
    font-family: 'Outfit-Bold';
    font-size: 20px;
    color: #fff;
    margin-bottom: 7px;
}

.track-card .track-input input {
    width: 70%;
    font-size: 16px;
    padding: 6px 15px;
    border: 2px solid #0006;
    border-right: 0px;
    border-radius: 6px 0px 0px 6px;
}

.track-card .track-input.track-input-error input {
    border-color: #df1010;
}

.track-card .track-input button {
    display: flex;
    align-items: center;
    border: none;
    border-radius: 0px 6px 6px 0px;
    padding: 6px 15px;
    background-color: var(--primary);
    color: #fff;
    font-size: 16px;
    transition: 0.3s;
}

.track-card .track-input button:hover {
    background-color: #1175c5;
}

.track-card .track-input button i {
    padding-right: 8px;
}

.track-card .resubmit-btn {
    position: relative;
    font-size: 18px;
    color: #fff;
    padding: 10px 20px;
    margin-bottom: 20px;
    border: none;
    border-radius: 6px;
    background-color: var(--primary);
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.20);
    transition: 0.3s;
}

.resubmit-btn1 {
    font-size: 14px;
    color: #fff;
    padding: 5px 12px;
    margin-bottom: 20px;
    border: none;
    border-radius: 6px;
    background-color: var(--primary);
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.20);
}

.track-card .resubmit-btn b {
    font-weight: 500;
}

.track-card .resubmit-btn i {
    padding-right: 5px;
}

.track-card .resubmit-btn:hover {
    transform: translateY(-5px);
    box-shadow: 1px 5px 10px rgba(0, 0, 0, 0.20);
}

.modal-popup h5 i {
    color: #000
}

.custom-dragger {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 278px;
    height: 337px;
    background-color: #fff !important;
    padding: 20px;
    border: 3px dashed #61616757;
    border-radius: 20px;
    text-align: center;
    cursor: pointer;
    transition: 0.3s;
}

.custom-dragger:hover {
    border-color: #1175c5;
}

.custom-dragger i {
    font-size: 2.5rem;
    color: var(--primary);
}

.custom-dragger h6 {
    font-family: 'Outfit-SemiBold';
    font-size: 20px;
    margin-top: 15px;
    margin-bottom: 13px;
}

.custom-dragger p {
    font-family: 'Outfit-Regular';
    font-size: 14px;
    margin-bottom: 0px;
    color: var(--secondary);
}

.custom-dragger .custom-btn {
    padding: 25px 0px;
    width: 100%;
    transition: 0.2s;
}

.custom-dragger .custom-btn:hover {
    background-color: #2495f13b;
}

.custom-dragger .custom-btn:first-child {
    border-bottom: 3px dashed #61616757;
}

.custom-dragger:hover .custom-btn:first-child {
    border-color: #1175c5;
}

.h-m-0 {
    height: auto;
}

.footer-text{
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
    color: #fff;
    display: block;
    width: 100%;
    text-align: center;
    font-size: 16px;
}

/* Lablet Responsive Design */
@media (max-width: 1060px) {

    .upload-card,
    .track-card {
        width: 90%;
    }

    .custom-table .ant-table {
        width: 100%;
        overflow-x: scroll;
    }
}

/* Mobile Responsive Design */
@media (max-width: 767px) {

    .navbar .nav-items .nav-link b,
    .navbar .nav-items .nav-btn b {
        display: none;
    }

    .navbar .nav-items .nav-link i,
    .navbar .nav-items .nav-btn i {
        padding-right: 0px;
        font-size: 22px;
    }

    .bg-style {
        padding: 100px 0px 50px;
    }

    .upload-card,
    .track-card {
        padding: 30px 20px;
        width: 90%;
        margin: 25px 0px;
    }

    .track-card {
        position: relative;
        padding-bottom: 40px;
    }

    .reupload-card {
        margin: 0px;
    }

    .upload-card .webcam-card,
    .upload-card .photo-upload {
        margin: 0px auto;
    }

    .upload-card .webcam-horizontal video {
        width: 100%;
    }

    .upload-card .submit-btn {
        margin: 10px auto 0px;
    }

    .upload-card .upload-list ol li .upload-file-card {
        min-width: 100%;
    }

    .upload-card .webcam-horizontal {
        width: 100%;
        height: 200px;
    }

    .custom-modal .ant-modal-body .content {
        font-family: 'Outfit-Regular';
        font-size: 14px;
    }

    .custom-table table .ant-table-thead th,
    .custom-table table .ant-table-tbody td {
        font-size: 14px;
    }

    .track-card .track-input {
        width: 100%;
    }

    .track-card .track-input label {
        font-size: 18px;
    }

    .track-card .resubmit-btn {
        position: absolute;
        left: 50%;
        bottom: 0px;
        transform: translateX(-50%);
        font-size: 16px;
        z-index: 9;
    }

    .h-100vh {
        height: 100vh;
    }
}

/* Small Mobile Responsive Design */
@media (max-width: 576px) {}