.custom-table table {
    border-radius: 20px !important;
    overflow: hidden;
}

.custom-table table .ant-table-thead th {
    background-color: var(--primary);
    font-family: 'Outfit-SemiBold';
    font-size: 16px;
    text-align: center;
    color: #fff;
}

.custom-table table .ant-table-tbody td {
    font-family: 'Outfit-Regular';
    font-size: 16px;
}

.custom-table table .ant-table-tbody td .ant-tag {
    font-family: 'Outfit-Regular';
    font-size: 14px;
}

.custom-modal h5{
    font-family: 'Outfit-Regular';
    font-size: 16px;
}

.custom-modal h6{
    font-family: 'Outfit-SemiBold';
    font-size: 16px;
}

.custom-modal .ant-modal-title {
    font-family: 'Outfit-SemiBold';
    font-size: 22px;
    text-align: center;
    color: var(--primary);
}

.custom-modal .ant-modal-header {
    margin-bottom: 0px;
}

.custom-modal .ant-modal-body .icon {
    display: block;
    font-size: 4rem;
    color: #05b105;
    text-align: center;
}

.custom-modal .ant-modal-body .content {
    font-family: 'Outfit-Regular';
    font-size: 16px;
    margin-bottom: 10px;
}

.custom-modal .ant-modal-body .copy-box {
    position: relative;
    display: inline-block;
    border: 2px solid var(--primary);
    border-radius: 8px;
    padding: 0px 15px;
    width: 80%;
}

.custom-modal .ant-modal-body .copy-box input {
    background-color: transparent;
    border: none;
    padding: 10px 0px;
    font-family: 'Outfit-SemiBold';
    font-size: 16px;
    width: 100%;
}

.custom-modal .ant-modal-body .copy-box button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    background-color: #fff;
    border: none;
    font-size: 20px;
    padding-left: 10px;
    box-shadow: 0px 0px 8px rgba(255, 255, 255, 0.568);
}

.custom-modal .ant-modal-body .copy-box button.copy-btn-active {
    color: var(--primary);
}

.custom-modal .ant-modal-body .note-text {
    font-family: 'Outfit-Regular';
    color: #ce0e0e;
    font-size: 14px;
    margin-top: 10px;
    margin-bottom: 0px;
}

.custom-modal .ant-modal-footer {
    text-align: center;
}

.custom-modal .ant-modal-footer button {
    font-family: 'Outfit-SemiBold';
    font-size: 16px;
    margin-inline-start: 0px !important;
    padding: 6px 25px;
    border-radius: 8px;
    height: auto;
}

.custom-modal .ant-modal-footer button:disabled {
    opacity: 0.8;
    color: #fff;
    background-color: var(--primary);
}

.custom-modal .ant-modal-close:hover {
    background-color: transparent;
    color: #ce0e0e;
}

.custom-table .ant-table {
    background-color: transparent;
}

.custom-table .ant-table .ant-table-tbody {
    background-color: #fff;
}

.reupload-modal .ant-modal-close {
    top: 20px;
    inset-inline-end: 20px;
}

.reupload-modal .ant-modal-close .ant-modal-close-x {
    font-size: 18px;
}

.reupload-modal .ant-modal-content {
    padding: 30px;
    border-radius: 30px;
}

@media (max-width: 767px) {
    .reupload-modal .ant-modal-content {
        padding: 20px 10px;
    }

    .reupload-modal .ant-modal-title {
        padding-left: 15px;
    }

    .pt-m-0 {
        padding-top: 0px;
    }
}